export const POST_SIGNUP_SUCCESS = "POST_SIGNUP_SUCCESS";
export const POST_SIGNUP_FAILED = "POST_SIGNUP_FAILED";
export const POST_SIGNIN_SUCCESS = "POST_SIGNIN_SUCCESS";
export const POST_EMAIL_SENT = "POST_EAMIL_SENT";
export const POST_SIGNIN_FAILED = "POST_SIGNIN_FAILED";
export const LOG_OUT = "LOG_OUT";
export const AUTHENTICATION_PENDING = "AUTHENTICATION_PENDING";
export const EMAILCONFIRMATION_PENDING = "EMAILCONFIRMATION_PENDING";
export const SET_PAYMENT = "SET_PAYMENT";
export const SET_USERS = "SET_USERS";
export const UPDATE_EMAIL = "[auth] UPDATE_EMAIL";
export const ATUH_UPDATE_INFO = "[auth] UPDATE_INFO";
export const UPDATE_REDIRECT_URL = "[auth] UPDATE_REDIRECT_URL";
export const UPDATE_ACTION_DATA = "[auth] UPDATE_ACTION_DATA";

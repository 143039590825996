const authRoles = {
  guest: ['guest'],
  root: ['root'],
  admin: ['admin'],
  developer: ['developer'],
  md: ['md'],
  premium_user: ['premium_user'],
  user: ['user'],
  triage: ['triage'],
};

export default authRoles;

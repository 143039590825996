export const OPEN_WIKI_LEFT_SIDEBAR = '[wiki] OPEN_WIKI_LEFT_SIDEBAR';
export const OPEN_WIKI_RIGHT_SIDEBAR = '[wiki] OPEN_WIKI_RIGHT_SIDEBAR';
export const FETCH_WIKI_BY_TAG_SUCCESS = '[wiki] FETCH_WIKI_BY_TAG_SUCCESS';
export const FETCH_WIKI_BY_ID_SUCCESS = '[wiki] FETCH_WIKI_BY_ID_SUCCESS';
export const FETCH_ALL_WIKI_DATA = '[wiki] FETCH_ALL_WIKI_DATA';
export const SET_ACTIVE_WIKI_ITEM = '[wiki] SET_ACTIVE_WIKI_ITEM';
export const UPDATE_WIKI_SUCCESS = '[wiki] UPDATE_WIKI_SUCCESS';
export const UPDATE_WIKI_FAILURE = '[wiki] UPDATE_WIKI_FAILURE';
export const CREATE_WIKI_SUCCESS = '[wiki] CREATE_WIKI_SUCCESS';
export const CREATE_WIKI_FAILURE = '[wiki] CREATE_WIKI_FAILURE';
export const DELETE_WIKI_SUCCESS = '[wiki] DELETE_WIKI_SUCCESS';
export const DELETE_WIKI_FAILURE = '[wiki] DELETE_WIKI_FAILURE';
export const NEW_WIKI_ID = 'NEW_WIKI_ID';
export const WIKI_ID_TYPE = 'WIKI_ID_TYPE';
export const WIKI_TAG_TYPE = 'WIKI_TAG_TYPE';
export const SET_WIKILIST_KEYWORD = 'SET_WIKILIST_KEYWORD';
export const SET_WIKILIST_DIALOG_VISIBLE = 'SET_WIKILIST_DIALOG_VISIBLE';
export const SET_WIKILIST_LOAD_PENDING = 'SET_WIKILIST_LOAD_PENDING';
